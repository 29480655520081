// whatsappTemplates.js

const sellerTemplate = `Looking to grow your business? 🚀

Vidyut Cloud (www.vidyutcloud.com) is a B2B electrical e-commerce platform for Transformers, Motors, Generators, Solar, Cables, and more!

✅ Get dedicated leads for your products
✅ Negotiate & finalize orders online
✅ Low subscription charges
✅ Technical support

Connect with us for more details:
📞 8169517831 | ✉️ info@vidyutcloud.com

Niharika Deshpande, Vidyut Cloud, Thane
GST: 27AAWFV4391F1ZH`;

const buyerTemplate = `Looking for electrical products like Transformers, Motors, Generators, Solar, and Cables? 🌟

Vidyut Cloud (www.vidyutcloud.com) connects buyers with manufacturers for free!

✅ Wide range of products
✅ Post requirements & get quotes
✅ Negotiate & finalize online

Join Vidyut Cloud now for a hassle-free buying experience!

Niharika Deshpande
📞 8169517831 | ✉️ info@vidyutcloud.com
🌐 www.vidyutcloud.com | GST: 27AAWFV4391F1ZH`;

const otherTemplate = ` `;

const enquiryTemplate=
`New Enquiry

Enquiry ID:- {enquiryID}

Customer Details:- {buyerName}, +91-{phoneNumber}, {emailID}

Technical Details:- 
{attributes}

Details are shared through Mail

Thanks & Regards,
Vidyutcloud Team
www.vidyutcloud.com`

// Export templates
module.exports = {
    sellerTemplate,
    buyerTemplate,
    otherTemplate,
    enquiryTemplate
};
