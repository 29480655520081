import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/global.css';
import { buyerTemplate, otherTemplate, enquiryTemplate } from '../../whatsapp_temp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEnvelope, faCommentDots, faBorderNone} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import data from '../../data.json';

const EnquiryList = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [editEnquiry, setEditEnquiry] = useState({});
    const [showTemplates, setShowTemplates] = useState({});
    const [customMessage, setCustomMessage] = useState({});
    const [customMessageVisible, setCustomMessageVisible] = useState({});
    const [sellers, setSellers] = useState([]);
    const [sellersbyenquiry, setSellersByEnquiry] = useState([]);
   // const [selectedSeller, setSelectedSeller] = useState(null);
    const [selectedSellers, setSelectedSellers] = useState([]);
    const [selectedEnquiry, setSelectedEnquiry] = useState(null); 
    const [showEnquiryButton, setShowEnquiryButton] = useState(false);
    const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [filteredEnquiries, setFilteredEnquiries] = useState([]); // New state for filtered buyers
    const [selectedEnquiries, setSelectedEnquiries] = useState({}); // New state to track selected checkboxes
    const [attributes, setAttributes] = useState([]);


    useEffect(() => {
        const fetchEnquiries = async () => {
            try {
                const response = await axios.get('http://localhost:5001/enquiries');
                const sortedEnquiries = response.data.sort((a, b) => a.enquiryID.localeCompare(b.enquiryID));
                setEnquiries(sortedEnquiries);
            } catch (error) {
                console.error('Error fetching enquiries:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchEnquiries();
    }, []);

    //Search functionality
    useEffect(() => {
            if (searchQuery.trim() === "") {
                setFilteredEnquiries(enquiries); // Reset to the full list if the query is empty
            } else {
                const filtered = enquiries.filter((enquiry) =>
                    enquiry.enquiryID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    enquiry.buyerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    enquiry.phoneNumber?.includes(searchQuery) ||
                    enquiry.emailID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    enquiry.state?.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setFilteredEnquiries(filtered);
            }
        }, [searchQuery, enquiries]);
    

    if (loading) {
        return <div>Loading enquiries...</div>;
    }

    const handleEditClick = async (enquiry) => {
        setEditEnquiry(enquiry);
        const response = await axios.get('http://localhost:5001/attributesByEnquiry', {
            params: { enquiry },
        });
        const attributes = response.data; // Assuming this is an array of attributes
        console.log('att resp', attributes);
        setAttributes(attributes); 
        setEditMode(true);
    };
    const handleAttributeChange = (index, name, value) => {
        const updatedAttributes = [...attributes];
        updatedAttributes[index][name] = value;
        setAttributes(updatedAttributes);  // Update the attributes in the state
    };
    const handleRemoveAttribute = (index) => {
        const updatedAttributes = attributes.filter((_, i) => i !== index);
        setAttributes(updatedAttributes);
    };
    
    const handleAddAttribute = () => {
        const newAttribute = { attributeName: '', attributeValue: '' }; // Define the structure of a new attribute
        setAttributes([...attributes, newAttribute]);
    };

    // Function to handle form input changes
    const handleInputChange = (e) => {
        setEditEnquiry({
            ...editEnquiry,
            [e.target.name]: e.target.value
        });
    };

    const saveChanges = async () => {
        console.log('Updating enquiry with data:',{ ...editEnquiry, attributes });  // Log the data being sent
    try {
        const response = await axios.put(
            `http://localhost:5001/enquiries/${editEnquiry.id}`,
           
            {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                },
                ...editEnquiry, // Send the enquiry details
                attributes,
            }
        );
        console.log('Enquiry updated:', response.data);
        setEnquiries((prevEnquiries) =>
            prevEnquiries.map((s) => (s.id === response.data.id ? response.data : s))
        );
        setEditMode(false);
    } catch (error) {
        console.error('Error updating enquiry:', error.message);
        if (error.response) {
            console.error('Error status:', error.response.status);
            console.error('Error response data:', error.response.data);
        }
    }
};

    // const sendWhatsapp = (enquiry, template) => {
    //     const whatsappMessage = template.replace("{buyerName}", enquiry.buyerName);
    //     const whatsappUrl = `https://wa.me/${enquiry.phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
    //     window.open(whatsappUrl, '_blank');
    // };

    const sendWhatsapp = async (seller, template) => {
        console.log ('Selected enq - ',selectedEnquiry)
        const enquiry = selectedEnquiry
        const response = await axios.get('http://localhost:5001/attributesByEnquiry', {
            params: { enquiry },
        });
        const attributes = response.data; // Assuming this is an array of attributes
        console.log('att resp', attributes);
        const technicalDetails = attributes
            .map(attr => `•⁠  ⁠${attr.attributeName}: ${attr.attributeValue}`)
            .join('\n');
        

        console.log('seller :-',seller)
        console.log('template :-',template)
       
        const whatsappMessage = template.replace("{Name}", seller.buyerName)
                                        .replace('{enquiryID}', selectedEnquiry.enquiryID)
                                        .replace('{buyerName}', selectedEnquiry.buyerName)
                                        .replace('{phoneNumber}', selectedEnquiry.phoneNumber.slice(2))
                                        .replace('{emailID}', selectedEnquiry.emailID)
                                        .replace('{attributes}', technicalDetails);
        const whatsappUrl = `https://wa.me/${seller.phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, '_blank');
    };

    const templates = [buyerTemplate, otherTemplate,enquiryTemplate];

    const handleTemplateSelect = (enquiry, templateIndex) => {
        if (templateIndex === 1) {
            setCustomMessageVisible((prev) => ({
                ...prev,
                [enquiry.id]: true,
            }));
        } else {
            setCustomMessageVisible((prev) => ({
                ...prev,
                [enquiry.id]: false,
            }));
            const template = templates[templateIndex];
            sendWhatsapp(enquiry, template);
        }
    };

    const toggleTemplates = (enquiryId) => {
        setShowTemplates((prev) => ({
            ...prev,
            [enquiryId]: !prev[enquiryId],
        }));
    };

    const deleteEnquiry = async (enquiryId) => {
        if (window.confirm("Are you sure you want to delete this enquiry?")) {
            try {
                const response = await axios.delete(`http://localhost:5001/enquiries/${enquiryId}`);
                console.log('Enquiry deleted:', response.data);
                setEnquiries((prevEnquiries) => prevEnquiries.filter((enquiry) => enquiry.id !== enquiryId));
            } catch (error) {
                console.error('Error deleting enquiry:', error);
                alert('Error deleting enquiry');
            }
        }
    };
    const fetchSellerByEnquiry = async (enquiry) => {
        console.log('Enquiry details:', enquiry);  // Log enquiry details if used elsewhere
        setSelectedEnquiry(enquiry);
       
        // Log seller data on button click
       // console.log(' selected enquiry  Data:',selectedEnquiry); 
      //  console.log('Seller id:', seller.id); 

      try {
        const response = await axios.get('http://localhost:5001/sellers/sellersByEnquiry', {
            params: { enquiryId: enquiry.id },
        });
        setSellersByEnquiry(response.data);
        console.log('Fetched sellers:', response.data);  // Correct place
    } catch (error) {
            console.error('Error fetching enquiries:', error);
        }
    }

    const handleSellerClick = async () => {
        if (!sellersbyenquiry) {
            console.log("No seller selected.");
            return;
        }
       console.log('sellers in handle seller click ,',sellersbyenquiry)
       try {
        const response = await axios.get('http://localhost:5001/sellers');
        const allSellers = response.data;
        console.log('all sellers ',allSellers)

        // Filter out sellers present in sellersbyenquiry
        const filteredSellers = allSellers.filter(
            (seller) => !sellersbyenquiry.some((enquirySeller) => enquirySeller.id === seller.id)
        );
        console.log('filtering sellers')

        setSellers(filteredSellers);  // Update the sellers list
        console.log('Filtered sellers:', filteredSellers);
    } catch (error) {
            console.error('Error fetching seller details:', error);
        }
    };
    const handleCheckboxChange = (sellerId) => {
       
        setSelectedSellers((prevSelected) => {
            if (prevSelected.includes(sellerId)) {
                // If already selected, remove it
                return prevSelected.filter(id => id !== sellerId);
            } else {
                // If not selected, add it
                return [...prevSelected, sellerId];
            }
        });
    };

    // Log the selected sellers
    const sendEnquiryToSellers = async() => {
        if (!selectedEnquiry) {
            console.log("No enquiry selected.");
            return;
        }
        const selectedSellersDetails = sellers.filter(seller =>
            selectedSellers.includes(seller.id)
        );
        console.log("Enquiry Details:", selectedEnquiry);
        console.log("Selected Sellers:", selectedSellersDetails);
        console.log("Selected enquiry subject:", selectedEnquiry.description);
        const subject = selectedEnquiry.description
        const body = {
            selectedEnquiry,
            selectedSellersDetails
        }
        try {
            for (const seller of selectedSellersDetails) {
                console.log('seller details ',seller)
                await sendEmail(selectedEnquiry, seller.emailID,subject);  // Await sending emails
            }
           // setSellers([]);
            alert("Emails sent successfully!");
        } catch (error) {
            console.error("Error sending emails:", error);
            alert("Error sending emails.");
        }
    
        sendEnquiryAndSellersToBackend(body);
        setSellers([])
    };
    const sendEnquiryAndSellersToBackend = async (body) => {
        try {
            const response = await axios.post('http://localhost:5001/enquiries/sendSeller', body);
            console.log('Response from server:', response.data);
        } catch (error) {
            console.error('Error sending enquiry and sellers:', error);
        }
    };

    const sendEmail = async (enquiry,sellerEmail,subject) => {
        console.log('seller email ', sellerEmail)
        try {
            const response = await axios.post(`http://localhost:5001/enquiries/${enquiry.id}/send-email`, {
                emailID: sellerEmail,
                subject: subject,
                enquiryInfo: {
                   // firstName: enquiry.firstName,
                   // lastName: enquiry.lastName,
                  //  companyName: enquiry.companyName,
                  //  phoneNumber: enquiry.phoneNumber,
                  //  emailID: enquiry.emailID,
                 //   city: enquiry.city,
                 //   state: enquiry.state,
                 enquiry,
                 sellerEmail
                },
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${sellerEmail}`);
    
            const updatedEnquiry = {
                ...enquiry,
                lastMailShared: new Date().toISOString(),
            };
    
            setEnquiries((prevEnquiries) => prevEnquiries.map((s) => (s.id === updatedEnquiry.id ? updatedEnquiry : s)));
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

      const handleEmailClick = (enquiry) => {
        setSelectedEnquiryId((prevId) => (prevId === enquiry.id ? null : enquiry.id));
    };
   
    
    return (
        <div>
            {sellersbyenquiry.length === 0 && (
                <>
                    <h2>Sellers List by Enquiry Id</h2>

                    <h3>No seller assign to selected enquiry </h3>
                    <button onClick={() =>handleSellerClick([])}>All Seller List</button>
                    <button onClick={() =>setSellersByEnquiry([])}>Back to Enquiry List</button>
                </>
            )}
            {sellersbyenquiry.length > 0 && (
                <>
                    <h2>Sellers List by Enquiry Id</h2>
                    <button onClick={() =>handleSellerClick([])}>All Seller List</button>
                    
                    <button onClick={() =>setSellersByEnquiry([])}>Back to Enquiry List</button>
                    <div className="sellers-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Company Name</th>
                                    <th>Phone Number</th>
                                    <th>Email ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sellersbyenquiry.map((seller) => (
                                    <tr key={seller.id}>
                                        
                                        <td>{`${seller.firstName} ${seller.lastName}`}</td>
                                        <td>{seller.companyName}</td>
                                        <div class="data">
                                            {seller.phoneNumber}
                                            <div class="data-container">
                                            <button onClick={() => sendWhatsapp( seller,templates[2] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>
                                            <button onClick={() => sendWhatsapp( seller,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                        </div>  
                                        </div>
                                        <td>{seller.emailID}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                </>
            )}
            {sellers.length > 0 && (
                <>
                    <h2>Sellers List</h2>
                    <button onClick={sendEnquiryToSellers}>Send Enquiry to Sellers</button>
                   
                   
                    <button onClick={() => setSellers([])}>Back to Enquiry List</button>
                    <div className="sellers-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Name</th>
                                    <th>Company Name</th>
                                    <th>Phone Number</th>
                                    <th>Email ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sellers.map((seller) => (
                                    <tr key={seller.id}>
                                        <td>
                                            <input 
                                                type="checkbox"
                                                checked={selectedSellers.includes(seller.id)}
                                                onChange={() => handleCheckboxChange(seller.id)}
                                            />
                                        </td>
                                        <td>{`${seller.firstName} ${seller.lastName}`}</td>
                                        <td>{seller.companyName}</td>
                                        <td>{seller.phoneNumber}</td>
                                        <td>{seller.emailID}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                </>
            )}
            <h2>Enquiry List</h2>

            <div className="search-container">
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by Enquiry ID, Buyer Name, Contact Number, Email ID, or State"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"/>
                </div>

            {editMode ? (
                <div className="edit-form">
                    <h3>Edit Enquiry</h3>
                    <label>
                        Buyer Name:
                        <input
                            type="text"
                            name="buyerName"
                            value={editEnquiry.buyerName}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Subject:
                        <input
                            type="text"
                            name="description"
                            value={editEnquiry.description}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Product Type:
                        <input
                            type="text"
                            name="productType"
                            value={editEnquiry.productType}
                            onChange={handleInputChange}
                        />
                    </label>
                    
                    <label htmlFor="phoneNumber">Phone Number:
                        <input
                            type="text"
                            name="phoneNumber"
                            value={editEnquiry.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label htmlFor="emailID">Email ID:
                        <input
                            type="text"
                            name="emailID"
                            value={editEnquiry.emailID}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label htmlFor="city">City:
                        <input
                            type="text"
                            name="city"
                            value={editEnquiry.city}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label htmlFor="state">State:
                        <select
                            name="state"
                            value={editEnquiry.state}
                            onChange={handleInputChange}
                        >
                            <option value="">Select State</option>
                            {data.states.map((state) => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                        </select>
                    </label>
                    <label htmlFor="status">Status:
                        <select
                            name="status"
                            value={editEnquiry.status}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Status</option>
                            {data.statusOptions.map((status) => (
                                <option key={status} value={status}>{status}</option>
                            ))}
                        </select>
                    </label>
                     {/* Edit Attributes Section */}
         {/* Displaying and editing attributes */}
         <h4>Attributes</h4>
         {attributes.length > 0 ? (
                    attributes.map((attribute, index) => (
                        <div key={index} className="attribute-row">
                            <label>
                                Attribute Name:
                                <input
                                    type="text"
                                    value={attribute.attributeName}
                                    onChange={(e) =>
                                        handleAttributeChange(index, 'attributeName', e.target.value)
                                    }
                                />
                            </label>

                            <label>
                                Attribute Value:
                                <input
                                    type="text"
                                    value={attribute.attributeValue}
                                    onChange={(e) =>
                                        handleAttributeChange(index, 'attributeValue', e.target.value)
                                    }
                                />
                            </label>

                            <button
                                type="button"
                                onClick={() => handleRemoveAttribute(index)}
                            >
                                Remove Attribute
                            </button>
                        </div>
                    ))
                ) : (
                    <div>No attributes found for this enquiry.</div>
                )}

                        {/* Add new attribute button */}
                        <button type="button" onClick={handleAddAttribute}>Add Attribute</button>                    <div className='submit-btn'>
                            <button type="submit" onClick={saveChanges}>Update Enquiry</button>
                            <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
                        </div>
                </div>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Sl. No</th>
                            <th>Enquiry ID</th>
                            <th>Date</th>
                            <th>Buyer Name</th>
                            <th>Request for quotation</th>
                            <th>Subject</th>
                            <th>Product Type</th>
                            <th>Contact Details</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Status</th>
                            <th>Seller</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {searchQuery? (
                            filteredEnquiries.length > 0 ?(
                                filteredEnquiries.map((enquiry, index) => (
                                <tr key={enquiry.id}>
                                    {/* Table content for filtered enquiry */}
                            <td>{index + 1}</td>
                                <td>{enquiry.enquiryID}</td>
                                <td>{new Date(enquiry.date).toLocaleDateString()}</td>
                                <td>{enquiry.buyerName}</td>
                                <td>{enquiry.quotationFor}</td>
                                <td>{enquiry.description}</td>
                                <td>{enquiry.productType}</td>
                                <td><div class= "data">
                                    {enquiry.emailID}
                                    {/* <div class ="data-container">
                                        <button onClick={() => sendEmail(enquiry)} title="Intro"><FontAwesomeIcon icon={faEnvelope}/></button>
                                        <button onClick={() => sendBannerEmail(enquiry)} title="Banner"><FontAwesomeIcon icon={faBorderNone}/></button>
                                    </div> */}
                                    </div>
                                    <div class="data">
                                    {enquiry.phoneNumber}
                                    <div class="data-container">
                                        <button onClick={() => sendWhatsapp( enquiry,templates[0] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>
                                        <button onClick={() => sendWhatsapp( enquiry,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                    </div>
                                </div>
                                </td>
                                <td>{enquiry.city}</td>
                                <td>{enquiry.state}</td>
                                <td>{enquiry.status}</td>
                                <td>
                                  <button onClick={() => fetchSellerByEnquiry(enquiry)}><FontAwesomeIcon icon={faUser}/></button>
                                </td>
                                <td>
                                    <button className="edit-btn" onClick={() => handleEditClick(enquiry)}><FontAwesomeIcon icon={faEdit}/></button>
                                    <button className="delete-btn" onClick={() => deleteEnquiry(enquiry.id)}><FontAwesomeIcon icon={faTrash}/></button>
                                </td> 
                            </tr>
                        ))
                            ):(
                                <tr>
                                        <td colSpan="15" style={{ textAlign: "center" }}>
                                            No results found.
                                        </td>
                                    </tr>
                            )
                        ):(
                        enquiries.map((enquiry, index) => (
                            <tr key={enquiry.id}>
                                <td>{index + 1}</td>
                                <td>{enquiry.enquiryID}</td>
                                <td>{new Date(enquiry.date).toLocaleDateString()}</td>
                                <td>{enquiry.buyerName}</td>
                                <td>{enquiry.quotationFor}</td>
                                <td>{enquiry.description}</td>
                                <td>{enquiry.productType}</td>
                                <td><div class= "data">
                                    {enquiry.emailID}
                                    {/* <div class ="data-container">
                                        <button onClick={() => sendEmail(enquiry)} title="Intro"><FontAwesomeIcon icon={faEnvelope}/></button>
                                        <button onClick={() => sendBannerEmail(enquiry)} title="Banner"><FontAwesomeIcon icon={faBorderNone}/></button>
                                    </div> */}
                                    </div>
                                    <div class="data">
                                    {enquiry.phoneNumber}
                                    <div class="data-container">
                                        <button onClick={() => sendWhatsapp( enquiry,templates[0] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>
                                        <button onClick={() => sendWhatsapp( enquiry,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                    </div>
                                </div>
                                </td>
                                <td>{enquiry.city}</td>
                                <td>{enquiry.state}</td>
                                <td>{enquiry.status}</td>
                                <td>
                                  <button onClick={() => fetchSellerByEnquiry(enquiry)}><FontAwesomeIcon icon={faUser}/></button>
                                </td>
                                <td>
                                    <button className="edit-btn" onClick={() => handleEditClick(enquiry)} title="Edit"><FontAwesomeIcon icon={faEdit}/></button>
                                    <button className="delete-btn" onClick={() => deleteEnquiry(enquiry.id)} title="Delete"><FontAwesomeIcon icon={faTrash}/></button>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            )}
             
        </div>
    );
};

export default EnquiryList; 
