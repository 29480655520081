
import React, { useState } from 'react';
import EnquiryForm from './EnquiryForm/EnquiryForm';
import EnquiryList from './EnquiryList/EnquiryList';
import Modal from '../component/Modal/Modal';

const ParentComponent = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const handleAddEnquiry = (newEnquiry) => {
        setEnquiries((prevEnquiries) => [...prevEnquiries, newEnquiry]);
        setShowForm(false);
    };

    const toggleFormVisibility = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    return (
        <div>
            <h1>Enquiries Management</h1>
            <button onClick={toggleFormVisibility}>
                {showForm ? 'Hide Form' : 'Add Enquiry'}
            </button>
            <Modal isOpen={showForm} onClose={toggleFormVisibility}>
                <EnquiryForm onAddEnquiry={handleAddEnquiry} />
            </Modal>
            <EnquiryList enquiries={enquiries} />
        </div>
    );
};

export default ParentComponent;
