
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/global.css";
import data from "../../data.json";
import { BASE_URL } from "../../component/apiConfig";
import { openWhatsApp } from "../../component/openWhatsApp";  // Import the reusable function
import { getWhatsAppMessage } from "../../component/getWhatsAppMessage";  // Import the reusable function

import { sellerTemplate, buyerTemplate, otherTemplate } from "../../whatsapp_temp";

const BuyerForm = ({ onAddBuyer }) => {
    const todayDate = new Date().toISOString().split("T")[0];
        const [formData, setFormData] = useState({
            firstName: "",
            lastName: "",
            companyName: "",
            phoneNumber: "91",
            emailID: "",
            city: "",
            state: "",
            notes: "",
            lastMailShared: todayDate,
        });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [customMessage, setCustomMessage] = useState({});
    const [emailExists, setEmailExists] = useState(false);
    
    // const [customMessageVisible, setCustomMessageVisible] = useState({});
    const [templates] = useState(["Buyer", "Other"]); // Added template options

    // Debounce to avoid API calls on every keystroke
    const debounce = (fn, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "phoneNumber" ? formatPhoneNumber(value) : value,
        }));

        // Call the debounce function for email field to check email existence
        if (name === "emailID") {
            debouncedCheckEmailExistence(value);
        }
    };

    const formatPhoneNumber = (input) => {
        if (!input.startsWith("91")) input = "91" + input;
        return input.replace(/[^0-9]/g, "").slice(0, 12);
    };

     // Debounced email checking
     const checkEmailExistence = async (email) => {
        if (!email) return;

        try {
            const response = await axios.get(`${BASE_URL}/check-email`, {
                params: { email },
            });
            setEmailExists(response.data.exists);
        } catch (error) {
            console.error("Error checking email existence:", error);
        }
    };

    const debouncedCheckEmailExistence = debounce(checkEmailExistence, 500);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !formData.firstName ||
            !formData.lastName ||
            !formData.emailID ||
            !formData.companyName
        ) {
            setErrorMessage("All fields are required.");
            return;
        }

        if (emailExists) {
            setErrorMessage("This email ID is already registered.");
            return;
        }

        try {
            const response = await axios.post(`${BASE_URL}/buyers`, {
                ...formData,
                selectedTemplate,
            });
            console.log("Buyer added:", response.data);
            onAddBuyer(response.data);
            setSuccessMessage("Buyer added successfully!");
            setErrorMessage("");

            // Use the appropriate WhatsApp template
            const message = getWhatsAppMessage(selectedTemplate, customMessage);

            // Open WhatsApp with the selected message
            openWhatsApp(formData.phoneNumber, message);

            // Reset form
            handleClear();
        } catch (error) {
            console.error("Error adding Buyer:", error.response?.data || error.message);
            setErrorMessage("An error occurred while adding the Buyer. Please try again.");
        }
    };

    const handleClear = () => {
        setFormData({
            firstName: "",
            lastName: "",
            companyName: "",
            phoneNumber: "91",
            emailID: "",
            website: "",
            city: "",
            state: "",
            notes: "",
            lastMailShared: todayDate,
        });
        setSelectedTemplate("");
        setCustomMessage("");
        setSuccessMessage("");
        setErrorMessage("");
        setEmailExists(false); // Clear email existence status
    };

    const handleTemplateChange = (e) => {
        const selectedIndex = e.target.value;
        setSelectedTemplate(templates[selectedIndex]);
        if (templates[selectedIndex] === "Other") {
            setCustomMessage("");
        }
    };

    return (
        <div className="sellerForm">
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    {/* Input fields */}
                    {[
                        { label: "First Name", name: "firstName", type: "text", placeholder: "First Name" },
                        { label: "Last Name", name: "lastName", type: "text", placeholder: "Last Name" },
                        { label: "Company Name", name: "companyName", type: "text", placeholder: "Company Name" },
                        { label: "Phone Number", name: "phoneNumber", type: "text", placeholder: "Enter phone number" },
                        { label: "Email ID", name: "emailID", type: "email", placeholder: "Email ID" },
                        { label: "City", name: "city", type: "text", placeholder: "City" },
                        { label: "Notes", name: "notes", type: "text", placeholder: "Notes" },
                    ].map((field) => (
                        <div className="form-group" key={field.name}>
                            <label htmlFor={field.name}>{field.label}</label>
                            <input
                                type={field.type}
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleInputChange}
                                placeholder={field.placeholder}
                                required={field.name === "emailID"}
                            />
                            {field.name === "emailID" && emailExists && (
                                <span className="error-message">This email is already taken.</span>
                            )}
                        </div>
                    ))}

                    {/* State Dropdown */}
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                        >
                            <option value="">Select State</option>
                            {data.states.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Date Picker */}
                    <div className="form-group">
                        <label htmlFor="lastMailShared">Mail Shared On</label>
                        <input
                            type="date"
                            name="lastMailShared"
                            value={formData.lastMailShared}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Template Selector */}
                    <div className="form-group template-options">
                        <label>Message</label>
                        <select onChange={handleTemplateChange}>
                            <option value="">Select a Message template</option>
                            {templates.map((template, index) => (
                                <option key={index} value={index}>
                                    {template}
                                </option>
                            ))}
                        </select>
                        {selectedTemplate === "Other" && (
                            <textarea
                                placeholder="Enter your custom message"
                                value={customMessage}
                                onChange={(e) => setCustomMessage(e.target.value)}
                            ></textarea>
                        )}
                    </div>
                </div>

                {/* Submit Buttons */}
                <div className="submit-btn">
                    <button className="primary" type="submit">Add Buyer</button>
                    <button className="secondary" type="button" onClick={handleClear}>Clear</button>
                </div>

                {/* Success Message */}
                {successMessage && <div className="success-message">{successMessage}</div>}
            
                {/* Error Message */}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>
        </div>
    );
};

export default BuyerForm;
