import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/global.css'; // Import the stylesheet
import { BASE_URL } from "../../component/apiConfig";
import { openWhatsApp } from "../../component/openWhatsApp";  // Import the reusable function
import { buyerTemplate, otherTemplate } from '../../whatsapp_temp';
import data from '../../data.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faEdit, faEnvelope,faNoteSticky, faBorderNone, faCommentDots} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';

const BuyerList = () => {
    const [buyers, setBuyers] = useState([]);
    const [filteredBuyers, setFilteredBuyers] = useState([]); // New state for filtered buyers
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [editMode, setEditMode] = useState(false);  // For toggling edit mode
    const [editBuyer, setEditBuyer] = useState({});  // Buyer being edited
    const [showTemplates, setShowTemplates] = useState({}); // To manage template visibility
    const [customMessage, setCustomMessage] = useState({}); // For handling custom message input
    const [customMessageVisible, setCustomMessageVisible] = useState({}); // To track visibility of the textarea
    const [loading, setLoading] = useState(true);
    const [selectedBuyers, setSelectedBuyers] = useState({}); // New state to track selected checkboxes
    const [showIntroButton, setShowIntroButton] = useState(false);
    const [selectedBuyerId, setSelectedBuyerId] = useState(null);

    // const [selectedTemplate, setSelectedTemplate] = useState(''); // To store the selected template

    // Fetch the Buyers from the backend
    useEffect(() => {
        const fetchBuyers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/buyers`);
                // Check for buyerID and sort buyers with valid IDs only
                const sortedBuyers = response.data.sort((a, b) => {
                    if (a.buyerID && b.buyerID) {
                        return a.buyerID.localeCompare(b.buyerID);
                    }
                    return 0; // No sorting if one of the buyerIDs is missing
                });
                setBuyers(sortedBuyers);
            } catch (error) {
                console.error('Error fetching buyers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchBuyers();
    }, []);

    // Automatically filter buyers as searchQuery changes
    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredBuyers(buyers); // Reset to the full list if the query is empty
        } else {
            const filtered = buyers.filter((buyer) =>
                buyer.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                buyer.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                buyer.phoneNumber?.includes(searchQuery) ||
                buyer.emailID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                buyer.state?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredBuyers(filtered);
        }
    }, [searchQuery, buyers]);

    if (loading) {
        return <div>Loading Buyer List...</div>;
    }

    // Handle "Select All" checkbox change
    const handleSelectAllChange = (e) => {
        const { checked } = e.target;
        const newSelectedBuyers = {};
        buyers.forEach((buyer) => {
            newSelectedBuyers[buyer.id] = checked;
        });
        setSelectedBuyers(newSelectedBuyers);
    };

    // Handle individual checkbox change
    const handleCheckboxChange = (buyerId) => {
        setSelectedBuyers((prev) => ({
            ...prev,
            [buyerId]: !prev[buyerId]
        }));
    };

    // Check if all buyers are selected
    const areAllSelected = buyers.length > 0 && buyers.every(buyer => selectedBuyers[buyer.id]);

    const sendWhatsapp = (buyer, template) => {
        if (!template) {
            console.error("Template is undefined or null.");
            return;
        }
    
        const whatsappMessage = template
            .replace("{firstName}", buyer.firstName || "")
            .replace("{lastName}", buyer.lastName || "")
            .replace("{companyName}", buyer.companyName || "");
    
        openWhatsApp(buyer.phoneNumber, whatsappMessage);
    
        const updatedBuyer = {
            ...buyer,
            lastWhatsAppShared: new Date().toISOString(),
        };
    
        setBuyers((prevBuyers) =>
            prevBuyers.map((b) => (b.id === updatedBuyer.id ? updatedBuyer : b))
        );
    
        updateBuyer(updatedBuyer);
    };

    // Predefined templates
    const templates = [
        buyerTemplate, otherTemplate,
    ];

    // Function to handle template selection and sending the message
    const handleTemplateSelect = (buyer, templateIndex) => {
        if (templateIndex === 1) {
            // Show textarea for custom message if "Other" is selected
            setCustomMessageVisible((prev) => ({
                ...prev,
                [buyer.id]: true // Only show for the specific buyer
            }));
        } else {
            // Hide the textarea if any predefined template is selected
            setCustomMessageVisible((prev) => ({
                ...prev,
                [buyer.id]: false
            }));
            const template = templates[templateIndex];
            sendWhatsapp(buyer, template);
        }
    };    

    // Function to toggle template options
    const toggleTemplates = (buyerId) => {
        setShowTemplates((prev) => ({
            ...prev,
            [buyerId]: !prev[buyerId] // Toggle the visibility for the specific buyer
        }));
    };

    // Function to handle buyer update (PUT request)
    const updateBuyer = async (buyerData) => {
        try {
            const response = await axios.put(`${BASE_URL}/buyers/${buyerData.id}`, buyerData, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            });
            console.log('buyer updated:', response.data);
            // Update the seller in the local list
            setBuyers(buyers.map(s => s.id === response.data.id ? response.data : s));
            setEditMode(false); // Exit edit mode
        } catch (error) {
            console.error('Error updating buyer:', error);
        }
    };

    // Bulk Send WhatsApp to selected sellers
    const bulkSendWhatsapp = (templateIndex) => {
        const template = templates[templateIndex];
        buyers.forEach(buyer => {
            if (selectedBuyers[buyer.id]) {
                sendWhatsapp(buyer, template);
            }
        });
    };

    // Bulk Send Email to selected sellers
    const bulkSendEmail = async () => {
        const selected = buyers.filter(buyer => selectedBuyers[buyer.id]);
        for (let buyer of selected) {
            await sendEmail(buyer);
        }
    };

    // Updated function to handle sending email
    const sendEmail = async (buyer) => {
        try {
            const response = await axios.post(`${BASE_URL}/buyers/${buyer.id}/send-email`, {
                emailID: buyer.emailID,
                subject: "Vidyutcloud - E-commerce Website For Electrical Products",  // New subject
                buyerInfo: {  // Pass relevant buyer info to the backend
                    firstName: buyer.firstName,
                    lastName: buyer.lastName,
                    companyName: buyer.companyName,
                    phoneNumber: buyer.phoneNumber,
                    emailID: buyer.emailID,
                    city: buyer.city,
                    state: buyer.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${buyer.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedBuyer = {
                ...buyer,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the buyer in the local state
            setBuyers(buyers.map(s => s.id === updatedBuyer.id ? updatedBuyer : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateBuyer(updatedBuyer);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };
    const sendBannerEmail = async (buyer) => {
        try {
            const response = await axios.post(`${BASE_URL}/buyers/${buyer.id}/send-emailBannerBuyer`, {
                emailID: buyer.emailID,
                subject: "Elevate Your Brand with Vidyut Cloud – Advertise Today!",  // New subject
                buyerInfo: {  // Pass relevant buyer info to the backend
                    firstName: buyer.firstName,
                    lastName: buyer.lastName,
                    companyName: buyer.companyName,
                    phoneNumber: buyer.phoneNumber,
                    emailID: buyer.emailID,
                    city: buyer.city,
                    state: buyer.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${buyer.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedBuyer = {
                ...buyer,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the buyer in the local state
            setBuyers(buyers.map(s => s.id === updatedBuyer.id ? updatedBuyer : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateBuyer(updatedBuyer);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    // Function to handle delete (DELETE request)
    const deleteBuyer = async (buyerId) => {
        if (window.confirm("Are you sure you want to delete this buyer?")) {
            try {
                const response = await axios.delete(`${BASE_URL}/buyers/${buyerId}`);
                console.log('buyer deleted:', response.data);
                // Remove the deleted buyer from the local list
                setBuyers(buyers.filter(buyer => buyer.id !== buyerId));
            } catch (error) {
                console.error('Error deleting buyer:', error);
                alert('Error deleting buyer');
            }
        }
    };

    // Function to handle edit click
    const handleEditClick = (buyer) => {
        setEditBuyer(buyer);
        setEditMode(true); // Enable edit mode
    };

    // Function to handle form input changes
    const handleChange = (e) => {
        setEditBuyer({
            ...editBuyer,
            [e.target.name]: e.target.value
        });
    };
    const handleEmailClick = (buyerId) => {
        setSelectedBuyerId((prevId) => (prevId === buyerId ? null : buyerId));
       // sendEmail(buyer); 
       // setShowIntroButton(true); 
      };

    const states = [
        'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Delhi','Chandigarh','Puducherry','Ladakh','Jammu and Kashmir','Lakshadweep'];

    // Check if any buyers are selected
    const anyBuyersSelected = Object.values(selectedBuyers).some(selected => selected);

    return (
        <div>
            <h2>Buyer List</h2>
                <div class="search-container">
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by First Name, Last Name, Contact Number, Email ID, or State"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        class="search-input"/>
                </div>

                {/* Bulk Actions */}
                {anyBuyersSelected && (
                    <div class="bulk-actions">
                        <button onClick={() => bulkSendEmail()}>Send Bulk Email</button>
                        <button onClick={() => bulkSendWhatsapp(0)}>Buyer</button>
                        <button onClick={() => bulkSendWhatsapp(1)}>Custom Message</button>
                    </div>
                )}
                {editMode ? (
                    <div class='editBuyer'>
                        <h3>Edit Buyer</h3>
                        <form class='editBuyerFrm' onSubmit={(e) => {
                            e.preventDefault();
                            updateBuyer(editBuyer); // Submit the updated Buyer
                        }}>
                            <div class="form-row">
                                <div class="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={editBuyer.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                    type="text"
                                    name="lastName"
                                    value={editBuyer.lastName}
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                    required
                                    />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="companyName">Company Name</label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        value={editBuyer.companyName}
                                        onChange={handleChange}
                                        placeholder="Company Name"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        value={editBuyer.phoneNumber}
                                        onChange={handleChange}
                                        placeholder="Phone Number"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="emailID">Email ID</label>
                                    <input
                                        type="email"
                                        name="emailID"
                                        value={editBuyer.emailID}
                                        onChange={handleChange}
                                        placeholder="Email ID"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        value={editBuyer.city}
                                        onChange={handleChange}
                                        placeholder="City"
                                    />
                                </div>
                                <div class="form-group">
                                    <label htmlFor="state">State</label>
                                    <select
                                        id="state"
                                        name="state"
                                        value={editBuyer.state}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select State</option>
                                        {states.map((state, index) => (
                                        <option key={index} value={state}>
                                            {state}
                                        </option>
                                        ))}
                                    </select>
                                    
                                </div>
                                <div class="form-group">
                                    <label htmlFor="notes">Notes</label>
                                    <input
                                        type="text"
                                        name="notes"
                                        value={editBuyer.notes}
                                        onChange={handleChange}
                                        placeholder="Notes"
                                    />
                                </div>
                            </div>
                            <div class='submit-btn'>
                                <button type="submit">Update Buyer</button>
                                <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                            <input className="select-all"
                                    type="checkbox"
                                    onChange={handleSelectAllChange}
                                    checked={areAllSelected}/>
                                <th>Sl. No</th>
                                <th>Name</th>
                                <th>Company Name</th>
                                <th>Phone Number</th>
                                <th>Email ID</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Notes</th>
                                <th>Communication</th>
                                {/* <th>Enquiry</th>  */}
                            </tr>
                        </thead>
                        <tbody>
                            {searchQuery ? (
                                filteredBuyers.length > 0 ? (
                                    filteredBuyers.map((buyer, index) => (
                                        <tr key={buyer.id}>
                                        {/* Table content for all buyers */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={!!selectedBuyers[buyer.id]}
                                                onChange={() => handleCheckboxChange(buyer.id)}
                                            />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div class="data">
                                                <div class="entry-name">
                                                    {`${buyer.firstName} ${buyer.lastName}`}
                                                </div>
                                                <div class="data-container">
                                                    <button onClick={() => handleEditClick(buyer)} title="Edit">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => deleteBuyer(buyer.id)} title="Delete">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </div>
                                        </td>

                                        <td>{buyer.companyName}</td>
                                        <td>
                                            <div className="data">
                                                {buyer.phoneNumber}
                                                {/* <div className="data-container"> */}
                                                    {/* WhatsApp Buyer Template Button */}
                                                    <button onClick={() => sendWhatsapp( buyer,templates[0] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>

                                                    {/* WhatsApp Other Template Button */}
                                                    <button onClick={() => sendWhatsapp( buyer,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                                {/* </div> */}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="data">
                                                {buyer.emailID}
                                                <div class="data-container">
                                                    <button onClick={() => sendEmail(buyer)} title="Intro"><FontAwesomeIcon icon={faEnvelope}/></button>
                                                    <button onClick={() => sendBannerEmail(buyer)} title="Banner"><FontAwesomeIcon icon={faBorderNone}/></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{buyer.city}</td>
                                        <td>{buyer.state}</td>
                                        <td>{buyer.notes}</td>
                                        <td>
                                            <div class="data">
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                                {buyer.lastMailShared ? new Date(buyer.lastMailShared).toLocaleDateString() : "N/A"}
                                            </div>
                                            <div class="data">
                                                <FontAwesomeIcon icon={faWhatsapp}/>
                                                {buyer.lastWhatsAppShared ? new Date(buyer.lastWhatsAppShared).toLocaleDateString() : "N/A"}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <button onClick={() => handleEditClick(buyer)} title="Enquiry"><FontAwesomeIcon icon={faNoteSticky}/></button>
                                        </td> */}
                                    </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="15" style={{ textAlign: "center" }}>
                                            No results found.
                                        </td>
                                    </tr>
                                )
                            ) : (
                                buyers.map((buyer, index) => (
                                    <tr key={buyer.id}>
                                        {/* Table content for all buyers */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={!!selectedBuyers[buyer.id]}
                                                onChange={() => handleCheckboxChange(buyer.id)}
                                            />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div class="data">
                                                <div class="entry-name">
                                                    {`${buyer.firstName} ${buyer.lastName}`}
                                                </div>
                                                <div class="data-container">
                                                    <button onClick={() => handleEditClick(buyer)} title="Edit">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button onClick={() => deleteBuyer(buyer.id)} title="Delete">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{buyer.companyName}</td>
                                        <td>
                                            <div className="data">
                                                {buyer.phoneNumber}
                                                {/* <div className="data-container"> */}
                                                    {/* WhatsApp Buyer Template Button */}
                                                    <button onClick={() => sendWhatsapp( buyer,templates[0] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>

                                                    {/* WhatsApp Other Template Button */}
                                                    <button onClick={() => sendWhatsapp( buyer,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                                {/* </div> */}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="data">
                                                {buyer.emailID}
                                                <div class="data-container">
                                                    <button onClick={() => sendEmail(buyer)} title="Intro"><FontAwesomeIcon icon={faEnvelope}/></button>
                                                    <button onClick={() => sendBannerEmail(buyer)} title="Banner"><FontAwesomeIcon icon={faBorderNone}/></button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{buyer.city}</td>
                                        <td>{buyer.state}</td>
                                        <td>{buyer.notes}</td>
                                        <td>
                                            <div class="data">
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                                {buyer.lastMailShared ? new Date(buyer.lastMailShared).toLocaleDateString() : "N/A"}
                                            </div>
                                            <div class="data">
                                                <FontAwesomeIcon icon={faWhatsapp}/>
                                                {buyer.lastWhatsAppShared ? new Date(buyer.lastWhatsAppShared).toLocaleDateString() : "N/A"}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <button onClick={() => handleEditClick(buyer)} title="Enquiry"><FontAwesomeIcon icon={faNoteSticky}/></button>
                                        </td> */}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    </div>
                )}
        </div>
    );
};

export default BuyerList;
