import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/global.css";
import data from "../../data.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

const EnquiryForm = ({ onAddEnquiry, enquiries = [] }) => {
    // State variables
    const [formData, setFormData] = useState({
        enquiryID: "",
        date: "",
        buyerName: "",
        description: "",
        quotationFor: "",
        productType: "",
        emailID: "",
        phoneNumber: "",
        city: "",
        state: "",
        status: "",
        attributes: [{ name: "", value: "" }],
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Effect to update the subject dynamically based on inputs
    useEffect(() => {
        const { enquiryID, quotationFor, city, state } = formData;
        if (enquiryID && quotationFor && city && state) {
            const subject = `${enquiryID}:- Request for quotation for ${quotationFor} at ${city}, ${state}`;
            setFormData((prev) => ({ ...prev, description: subject }));
        }
    }, [formData.enquiryID, formData.quotationFor, formData.city, formData.state]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleAttributeChange = (index, field, value) => {
        const updatedAttributes = [...formData.attributes];
        updatedAttributes[index][field] = value;
        setFormData((prev) => ({ ...prev, attributes: updatedAttributes }));
    };

    const handleAddAttribute = () => {
        setFormData((prev) => ({
            ...prev,
            attributes: [...prev.attributes, { name: "", value: "" }],
        }));
    };

    const handleRemoveAttribute = (index) => {
        if (formData.attributes.length > 1) {
            setFormData((prev) => ({
                ...prev,
                attributes: prev.attributes.filter((_, i) => i !== index),
            }));
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("http://localhost:5001/enquiries", formData);
            setSuccessMessage("Enquiry added successfully!");
            setErrorMessage("");
            onAddEnquiry(response.data);
            clearFormFields();
        } catch (error) {
            setErrorMessage("Error adding enquiry. Please try again.");
            setSuccessMessage("");
        }
    };

    const clearFormFields = () => {
        setFormData({
            enquiryID: "",
            date: "",
            buyerName: "",
            description: "",
            quotationFor: "",
            productType: "",
            emailID: "",
            phoneNumber: "",
            city: "",
            state: "",
            status: "",
            attributes: [{ name: "", value: "" }],
        });
    };

    return (
        <div className="sellerForm">
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    {/* Form Fields */}
                    {[
                        { label: "Enquiry ID", type: "text", name: "enquiryID", required: true },
                        { label: "Date", type: "date", name: "date", required: true },
                        { label: "Product Name", type: "text", name: "quotationFor", required: true },
                        { label: "Subject", type: "text", name: "description", required: true, readOnly: true },
                        { label: "Buyer Name", type: "text", name: "buyerName", required: true },
                        { label: "Email ID", type: "email", name: "emailID", placeholder: "Email ID" },
                        { label: "Phone Number", type: "text", name: "phoneNumber", placeholder: "Enter phone number" },
                        { label: "City", type: "text", name: "city", required: true },
                    ].map((field, index) => (
                        <div className="form-group" key={index}>
                            <label htmlFor={field.name}>{field.label}</label>
                            <input
                                type={field.type}
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                placeholder={field.placeholder}
                                required={field.required || false}
                                readOnly={field.readOnly || false}
                            />
                        </div>
                    ))}

                    {/* State Dropdown */}
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                        >
                            <option value="">Select State</option>
                            {data.states.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Product Type Dropdown */}
                    <div className="form-group">
                        <label htmlFor="productType">Product Type</label>
                        <select
                            name="productType"
                            value={formData.productType}
                            onChange={handleChange}
                        >
                            <option value="">Select Product</option>
                            {data.products.map((productType, index) => (
                                <option key={index} value={productType}>
                                    {productType}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Status Dropdown */}
                    <div className="form-group">
                        <label>Status</label>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a Status</option>
                            {data.statusOptions.map((status, index) => (
                                <option key={index} value={status}>
                                    {status}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Attributes */}
                    <div className="form-group">
                        <label>Attributes</label>
                        {formData.attributes.map((attribute, index) => (
                            <div key={index} className="attribute-row">
                                <input
                                    type="text"
                                    placeholder="Attribute Name"
                                    value={attribute.name}
                                    onChange={(e) =>
                                        handleAttributeChange(index, "name", e.target.value)
                                    }
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Attribute Value"
                                    value={attribute.value}
                                    onChange={(e) =>
                                        handleAttributeChange(index, "value", e.target.value)
                                    }
                                    required
                                />
                                {index > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveAttribute(index)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                )}
                                {index === formData.attributes.length - 1 && (
                                    <button type="button" onClick={handleAddAttribute}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Submit and Reset Buttons */}
                <div className="submit-btn">
                    <button type="submit">Add Enquiry</button>
                    <button type="button" onClick={clearFormFields}>
                        Reset
                    </button>
                </div>

                {/* Messages */}
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </form>
        </div>
    );
};

export default EnquiryForm;
