import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/global.css';
import { sellerTemplate, otherTemplate } from '../../whatsapp_temp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faEdit, faEnvelope, faGlobe, faBorderNone, faFileEdit, faNoteSticky,faCommentDots} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import data from '../../data.json';
import EnquiriesBySeller from './enquiriesBySeller';

    const SellerList = () => {
    const [sellers, setSellers] = useState([]);
    const [editMode, setEditMode] = useState(false);  // For toggling edit mode
    const [editSeller, setEditSeller] = useState({});  // Seller being edited
    const [showTemplates, setShowTemplates] = useState({}); // To manage template visibility
    const [customMessage, setCustomMessage] = useState(""); // For handling custom message input
    const [customMessageVisible, setCustomMessageVisible] = useState({}); // To track visibility of the textarea
    const [loading, setLoading] = useState(true);
    const [selectedSellers, setSelectedSellers] = useState({}); // New state to track selected checkboxes
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [filteredSellers, setFilteredSellers] = useState([]); // New state for filtered buyers
    // const [selectedTemplate, setSelectedTemplate] = useState(''); // To store the selected template
    const anySellersSelected = Object.values(selectedSellers).some(selected => selected); // Check if any sellers are selected
    const [enquiries, setEnquiries] = useState([]);
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [inquiryLoading, setInquiryLoading] = useState(false);
    const [selectedSeller, setSelectedSeller] = useState(null);
     const [selectedSellerId, setSelectedSellerId] = useState(null);
    
    // Fetch the sellers from the backend
    useEffect(() => {
        const fetchSellers = async () => {
            try {
                const response = await axios.get('http://localhost:5001/sellers');
                // Check for sellerID and sort sellers with valid IDs only
                const sortedSellers = response.data.sort((a, b) => {
                    if (a.sellerID && b.sellerID) {
                        return a.sellerID.localeCompare(b.sellerID);
                    }
                    return 0; // No sorting if one of the sellerIDs is missing
                });
                    setSellers(sortedSellers);
            } catch (error) {
                console.error('Error fetching sellers:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchSellers();
    }, []);

    // Automatically filter buyers as searchQuery changes
    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredSellers(sellers); // Reset to the full list if the query is empty
        } else {
            const filtered = sellers.filter((seller) =>
                seller.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                seller.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                seller.phoneNumber?.includes(searchQuery) ||
                seller.emailID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                seller.state?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredSellers(filtered);
        }
    }, [searchQuery, sellers]);

    if (loading) {
        return <div>Loading Seller List...</div>;
    }

    // Handle "Select All" checkbox change
    const handleSelectAllChange = (e) => {
        const { checked } = e.target;
        const newSelectedSellers = {};
        sellers.forEach((seller) => { 
            newSelectedSellers[seller.id] = checked;
        });
        setSelectedSellers(newSelectedSellers);
    };

    // Handle individual checkbox change
    const handleCheckboxChange = (sellerId) => {
        setSelectedSellers((prev) => ({
            ...prev,
            [sellerId]: !prev[sellerId]
        }));
    };

    // Check if all sellers are selected
    const areAllSelected = sellers.length > 0 && sellers.every(seller => selectedSellers[seller.id]);

    // Function to handle sending WhatsApp message
    const sendWhatsapp = (seller, template) => {
        // Create the WhatsApp message by replacing placeholders with actual buyer details
        const whatsappMessage = template
            .replace("{firstName}", seller.firstName)
            .replace("{lastName}", seller.lastName)
            .replace("{companyName}", seller.companyName);

        // WhatsApp URL encoding the message and phone number
        const whatsappUrl = `https://wa.me/${seller.phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;

        // Open WhatsApp Web with the message
        window.open(whatsappUrl, '_blank');

        // Create updated Seller object with the current date for lastWhatsAppShared
        const updatedSeller = {
            ...seller , lastWhatsAppShared: new Date().toISOString() // Set current date as lastWhatsAppShared
        };

        // Update the seller in the local state
        setSellers(sellers.map(s => s.id === updatedSeller.id ? updatedSeller : s));

        // Optionally, you can make an API call to update this in the backend as well
        updateSeller(updatedSeller);
    };

    // Predefined templates
    const templates = [sellerTemplate, otherTemplate];

    // Function to handle template selection and sending the message
    const handleTemplateSelect = (seller, templateIndex) => {
        if (templateIndex === 1) {
            // Show textarea for custom message if "Other" is selected
            setCustomMessageVisible((prev) => ({
                ...prev,[seller.id]: true // Only show for the specific seller
            }));
        } else {
            // Hide the textarea if any predefined template is selected
            setCustomMessageVisible((prev) => ({
                ...prev,[seller.id]: false
            }));
            const template = templates[templateIndex];
            sendWhatsapp(seller, template);
        }
    };    

    // Function to toggle template options
    const toggleTemplates = (sellerId) => {
        setShowTemplates((prev) => ({
            ...prev, [sellerId]: !prev[sellerId] // Toggle the visibility for the specific seller
        }));
    };

    // Function to handle seller update (PUT request)
    const updateSeller = async (sellerData) => {
        try {
            const response = await axios.put(`http://localhost:5001/sellers/${sellerData.id}`, sellerData, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            });
            console.log('Seller updated:', response.data);
            // Update the seller in the local list
            setSellers(sellers.map(s => s.id === response.data.id ? response.data : s));
            setEditMode(false); // Exit edit mode
        } catch (error) {
            console.error('Error updating seller:', error);
        }
    };

    // Bulk Actions Subcomponent
    const BulkActions = ({ anySellersSelected, bulkSendEmail, bulkSendWhatsapp }) => {
        if (!anySellersSelected) return null;
        return (
            <div class="bulk-actions">
                <button onClick={bulkSendEmail}>Send Bulk Email</button>
                <button onClick={() => bulkSendWhatsapp(0)}>Send WhatsApp</button>
                <button onClick={() => bulkSendWhatsapp(1)}>Custom Message</button>
            </div>
        );
    };

    // Bulk Send WhatsApp to selected sellers
    const bulkSendWhatsapp = (templateIndex) => {
        const template = templates[templateIndex];
        sellers.forEach(seller => {
            if (selectedSellers[seller.id]) {
                sendWhatsapp(seller, template);
            }
        });
    };

    // Bulk Send Email to selected sellers
    const bulkSendEmail = async () => {
        const selected = sellers.filter(seller => selectedSellers[seller.id]);
        for (let seller of selected) {
            await sendEmail(seller);
        }
    };

    // Updated function to handle sending email
    const sendEmail = async (seller) => {
        try {
            const response = await axios.post(`http://localhost:5001/sellers/${seller.id}/send-email`, {
                emailID: seller.emailID,
                subject: "Vidyutcloud - E-commerce Website For Electrical Products",  // New subject
                sellerInfo: {  // Pass relevant seller info to the backend
                    firstName: seller.firstName,
                    lastName: seller.lastName,
                    companyName: seller.companyName,
                    phoneNumber: seller.phoneNumber,
                    emailID: seller.emailID,
                    website: seller.website,
                    city: seller.city,
                    state: seller.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${seller.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedSeller = {
                ...seller,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the seller in the local state
            setSellers(sellers.map(s => s.id === updatedSeller.id ? updatedSeller : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateSeller(updatedSeller);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    const sendProposalEmail = async (seller) => {
        try {
            const response = await axios.post(`http://localhost:5001/sellers/${seller.id}/send-emailSellerProposal`, {
                emailID: seller.emailID,
                subject: "Proposal for VidyutCloud B2B Electrical Marketplace Subscription",  // New subject
                sellerInfo: {  // Pass relevant seller info to the backend
                    firstName: seller.firstName,
                    lastName: seller.lastName,
                    companyName: seller.companyName,
                    phoneNumber: seller.phoneNumber,
                    emailID: seller.emailID,
                    website: seller.website,
                    city: seller.city,
                    state: seller.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${seller.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedSeller = {
                ...seller,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the seller in the local state
            setSellers(sellers.map(s => s.id === updatedSeller.id ? updatedSeller : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateSeller(updatedSeller);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    const sendBannerEmail = async (seller) => {
        try {
            const response = await axios.post(`http://localhost:5001/sellers/${seller.id}/send-emailSellerBanner`, {
                emailID: seller.emailID,
                subject: "Elevate Your Brand with Vidyut Cloud – Advertise Today!",  // New subject
                sellerInfo: {  // Pass relevant seller info to the backend
                    firstName: seller.firstName,
                    lastName: seller.lastName,
                    companyName: seller.companyName,
                    phoneNumber: seller.phoneNumber,
                    emailID: seller.emailID,
                    website: seller.website,
                    city: seller.city,
                    state: seller.state
                }
            });
            console.log('Email sent:', response.data);
            alert(`Email sent to ${seller.emailID}`);

            // Update the "lastMailShared" date after successful email
            const updatedSeller = {
                ...seller,
                lastMailShared: new Date().toISOString() // Set current date as lastMailShared
            };

            // Update the seller in the local state
            setSellers(sellers.map(s => s.id === updatedSeller.id ? updatedSeller : s));

            // Optionally, you can make an API call to update this in the backend as well
            await updateSeller(updatedSeller);
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    // Function to handle delete (DELETE request)
    const deleteSeller = async (sellerId) => {
        if (window.confirm("Are you sure you want to delete this seller?")) {
            try {
                const response = await axios.delete(`http://localhost:5001/sellers/${sellerId}`);
                console.log('Seller deleted:', response.data);
                // Remove the deleted seller from the local list
                setSellers(sellers.filter(seller => seller.id !== sellerId));
            } catch (error) {
                console.error('Error deleting seller:', error);
                alert('Error deleting seller');
            }
        }
    };

    const fetchInquiryBySeller = async (seller) => {
        // Log seller data on button click
        console.log('Seller Data:', seller); 
        console.log('Seller id:', seller.id); 

        try {
            const response = await axios.get('http://localhost:5001/enquiries/enquiriesBySeller', {
                params: { sellerId: seller.id },
            });
            
            console.log('Fetched Enquiries:', response.data); // Log fetched data

            setEnquiries(response.data); 
          //  setSelectedSeller(seller); // Set selected seller
        } catch (error) {
            console.error('Error fetching enquiries:', error);
        }
       };

    // Function to handle edit click
    const handleEditClick = (seller) => {
        setEditSeller(seller);
        setEditMode(true); // Enable edit mode
    };

    // Function to handle form input changes
    const handleChange = (e) => {
        setEditSeller({
            ...editSeller,
            [e.target.name]: e.target.value
        });
    };
    
    const handleEmailClick = (sellerId) => {
        setSelectedSellerId((prevId) => (prevId === sellerId ? null : sellerId));
       // sendEmail(buyer); 
       // setShowIntroButton(true); 
      };

    
    // Sub-component: EditForm
    const EditForm = ({ editSeller, handleChange, updateSeller, setEditMode }) => {
        return (
            <div>
                <h3>Edit Seller</h3>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateSeller(editSeller);
                    }} >

                    <div class="form-row">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" name="firstName" value={editSeller.firstName} onChange={handleChange} placeholder="First Name"/>
                        
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" name="lastName" value={editSeller.lastName} onChange={handleChange} placeholder="Last Name"/>

                        <label htmlFor="companyName">Company Name</label>
                        <input type="text" name="companyName" value={editSeller.companyName} onChange={handleChange} placeholder="Company Name" />

                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input type="text" name="phoneNumber" value={editSeller.phoneNumber} onChange={handleChange} placeholder="Phone Number" />

                        <label htmlFor="emailID">Email ID</label>
                        <input type="email" name="emailID" value={editSeller.emailID} onChange={handleChange} placeholder="Email ID"/>

                        <label htmlFor="website">Website</label>
                        <input type="url" name="website" value={editSeller.website} onChange={handleChange} placeholder="Website" />

                        <label htmlFor="city">City</label>
                        <input type="text" name="city" value={editSeller.city} onChange={handleChange} placeholder="City" />

                        <label htmlFor="state">State</label>
                        <input type="text" name="state" value={editSeller.state} onChange={handleChange} placeholder="State" />
                        <label htmlFor="notes">Notes</label>
                        <input type="text" name="notes" value={editSeller.notes} onChange={handleChange} placeholder="Notes" />
                    </div>

                    <div class="submit-btn">
                        <button type="submit" title="Update Seller">Update Seller</button>
                        <button type="button" onClick={() => setEditMode(false)} title="Cancel">Cancel</button>
                    </div>
                </form>
            </div>
        );
    };
   
    return (
        <div>
            {enquiries.length === 0 && (
                <>
                    <h2> Enquiry List</h2>
                    <h3>No enquiry assign to selected seller</h3>
                    <button onClick={() => setEnquiries([])}>Back to Seller List</button>
                </>
            )}
            {enquiries.length > 0 && (
                <>
                    <h2>Enquiry List</h2>
                   
                    <button onClick={() => setEnquiries([])}>Back to Seller List</button>
                   
                    <div class="enquiry-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Enquiry Id</th>
                                    <th>Buyer Name</th>
                                    <th>Description</th>
                                    <th>Product Type</th>
                                    <th>Phone No</th>
                                    <th>Email ID</th>
                                    <th>City</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>
                                {enquiries.map((enquiries) => (
                                    <tr key={enquiries.id}>
                                        <td>{enquiries.enquiryID}</td>
                                        <td>{enquiries.buyerName}</td>
                                        <td>{enquiries.description}</td>
                                        <td>{enquiries.productType}</td>
                                        <td>{enquiries.phoneNumber}</td>
                                        <td>{enquiries.emailID}</td>
                                        <td>{enquiries.city}</td>
                                        <td>{enquiries.state}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                </>
            )}
            <h2>Seller List</h2>
            <BulkActions
                anySellersSelected={anySellersSelected}
                    bulkSendEmail={bulkSendEmail}
                    bulkSendWhatsapp={bulkSendWhatsapp}
            />
            <div class="search-container">
                    {/* Search Input */}
                    <input type="text" placeholder="Search by First Name, Last Name, Contact Number, Email ID, or State" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} class="search-input"/>
                </div>
          
            {editMode ? (
                <div>
                    <h3>Edit Seller</h3>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        updateSeller(editSeller); // Submit the updated seller
                    }}>
                        <div class="form-row">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" name="firstName" value={editSeller.firstName} onChange={handleChange} placeholder="First Name" required />

                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" name="lastName" value={editSeller.lastName} onChange={handleChange} placeholder="Last Name" required />
                        
                            <label htmlFor="companyName">Company Name</label>
                            <input type="text" name="companyName" value={editSeller.companyName} onChange={handleChange} placeholder="Company Name" required />

                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input type="text" name="phoneNumber" value={editSeller.phoneNumber} onChange={handleChange} placeholder="Phone Number" required />

                            <label htmlFor="emailID">Email ID</label>
                            <input type="email" name="emailID" value={editSeller.emailID} onChange={handleChange} placeholder="Email ID" required />

                            <label htmlFor="website">Website</label>
                            <input type="url" name="website" value={editSeller.website} onChange={handleChange} placeholder="Website"/>

                            <label htmlFor="city">City</label>
                            <input type="text" name="city" value={editSeller.city} onChange={handleChange} placeholder="City" required />

                            <label htmlFor="state">State</label>
                            <select id="state" name="state" value={editSeller.state} onChange={handleChange} required >
                                <option value="">Select State</option>
                                {data.states.map((state, index) => (
                                <option key={index} value={state}>
                                    {state}
                                </option>
                                ))}
                            </select>

                            <label htmlFor="notes">Notes</label>
                            <input type="text" name="notes" value={editSeller.notes} onChange={handleChange} placeholder="Notes" />
                        </div>

                        <div class='submit-btn'>
                            <button type="submit" title="Update Seller">Update Seller</button>
                            <button type="button" onClick={() => setEditMode(false)} title="Cancel">Cancel</button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="table-container">
                <table>
                    <thead>
                        <tr>
                        <input className= "select-all"type="checkbox" onChange={handleSelectAllChange} checked={areAllSelected}/>
                            <th>Sl. No</th>
                            <th>Name</th>
                            <th>Company Name</th>
                            <th>Phone Number</th>
                            <th>Email ID</th>
                            <th>State</th>
                            <th>Notes</th>
                            <th>Communication</th>
                            <th>Enquiry</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {searchQuery? (
                            filteredSellers.length > 0 ?(
                                filteredSellers.map((seller, index) => (
                                <tr key={seller.id}>
                                    {/* Table content for filtered buyers */}
                            <td>
                                <input className="select-all" type="checkbox" checked={!!selectedSellers[seller.id]} onChange={() => handleCheckboxChange(seller.id)} />
                            </td>
                                <td>{index + 1}</td> {/* This generates the serial number */}
                                <td>
                                    <div class="data">
                                        <div class="entry-name">
                                            {`${seller.firstName} ${seller.lastName}`}
                                            </div>
                                            <div class="data-container">
                                                <button onClick={() => handleEditClick(seller)} title="Edit" class="edit-btn"><FontAwesomeIcon icon={faEdit}/></button>
                                                <button onClick={() => deleteSeller(seller.id)} title="Delete" class="delete-btn" ><FontAwesomeIcon icon={faTrash}/></button>
                                            </div>
                                    </div>
                                    </td>                                
                                    <td>
                                    <div class="data">
                                        {seller.companyName}
                                        <div class="data-container">
                                            <button onClick={() => window.open(seller.website, '_blank')} title="Website"> <FontAwesomeIcon icon={faGlobe}/></button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="data">
                                        {seller.phoneNumber}
                                        {/* <div className="data-container"> */}
                                            {/* WhatsApp Seller Template Button */}
                                            <button onClick={() => sendWhatsapp( seller,templates[0] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>

                                            {/* WhatsApp Other Template Button */}
                                            <button onClick={() => sendWhatsapp( seller,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                        {/* </div> */}
                                    </div>
                                </td>
                                <td><div class="data">
                                    {seller.emailID}
                                    <div class="data-container">
                                        <button onClick={() => sendEmail(seller)} title="Intro"><FontAwesomeIcon icon={faEnvelope}/></button>
                                        <button onClick={() => sendProposalEmail(seller)} title="Proposal"><FontAwesomeIcon icon={faFileEdit}/></button>
                                        <button onClick={() => sendBannerEmail(seller)} title="Banner"><FontAwesomeIcon icon={faBorderNone}/></button>
                                    </div>
                                </div>
                                </td>
                                <td>{seller.state}</td>
                                <td>{seller.notes}</td>
                                <td>
                                        <div class="data">
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                            {seller.lastMailShared ? new Date(seller.lastMailShared).toLocaleDateString() : "N/A"}
                                        </div>
                                        <div class="data">
                                            <FontAwesomeIcon icon={faWhatsapp}/>
                                            {seller.lastWhatsAppShared ? new Date(seller.lastWhatsAppShared).toLocaleDateString() : "N/A"}
                                        </div>
                                    </td>
                                <td>
                                    <button onClick={() => fetchInquiryBySeller(seller)} title="Enquiry" ><FontAwesomeIcon icon={faNoteSticky}/></button>
                                </td>
                                </tr>
                        ))
                            ):(
                                <tr>
                                    <td colSpan="15" style={{ textAlign: "center" }}>No results found.</td>
                                </tr>
                            )
                        ):(
                            sellers.map((seller, index) => (
                                <tr key={seller.id}>
                                    {/* Table content for filtered buyers */}
                            <td>
                                <input className="select-all" type="checkbox" checked={!!selectedSellers[seller.id]} onChange={() => handleCheckboxChange(seller.id)} />
                            </td>
                                <td>{index + 1}</td> {/* This generates the serial number */}
                                <td>
                                    <div class="data">
                                        <div class="entry-name">
                                            {`${seller.firstName} ${seller.lastName}`}
                                            </div>
                                            <div class="data-container">
                                                <button onClick={() => handleEditClick(seller)} title="Edit" class="edit-btn"><FontAwesomeIcon icon={faEdit}/></button>
                                                <button onClick={() => deleteSeller(seller.id)} title="Delete" class="delete-btn" ><FontAwesomeIcon icon={faTrash}/></button>
                                            </div>
                                    </div>
                                    </td>                                
                                    <td>
                                    <div class="data">
                                        {seller.companyName}
                                        <div class="data-container">
                                            <button onClick={() => window.open(seller.website, '_blank')} title="Website"> <FontAwesomeIcon icon={faGlobe}/></button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="data">
                                        {seller.phoneNumber}
                                        {/* <div className="data-container"> */}
                                            {/* WhatsApp Seller Template Button */}
                                            <button onClick={() => sendWhatsapp( seller,templates[0] )} title="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} /></button>

                                            {/* WhatsApp Other Template Button */}
                                            <button onClick={() => sendWhatsapp( seller,templates[1] )} title="CustomWhatsApp"><FontAwesomeIcon icon={faCommentDots} /></button>
                                        {/* </div> */}
                                    </div>
                                </td>
                                <td><div class="data">
                                    {seller.emailID}
                                    <div class="data-container">
                                        <button onClick={() => sendEmail(seller)} title="Intro"><FontAwesomeIcon icon={faEnvelope}/></button>
                                        <button onClick={() => sendProposalEmail(seller)} title="Proposal"><FontAwesomeIcon icon={faFileEdit}/></button>
                                        <button onClick={() => sendBannerEmail(seller)} title="Banner"><FontAwesomeIcon icon={faBorderNone}/></button>
                                    </div>
                                </div>
                                </td>
                                <td>{seller.state}</td>
                                <td>{seller.notes}</td>
                                <td>
                                        <div class="data">
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                            {seller.lastMailShared ? new Date(seller.lastMailShared).toLocaleDateString() : "N/A"}
                                        </div>
                                        <div class="data">
                                            <FontAwesomeIcon icon={faWhatsapp}/>
                                            {seller.lastWhatsAppShared ? new Date(seller.lastWhatsAppShared).toLocaleDateString() : "N/A"}
                                        </div>
                                    </td>
                                <td>
                                    <button onClick={() => fetchInquiryBySeller(seller)} title="Enquiry" ><FontAwesomeIcon icon={faNoteSticky}/></button>
                                </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                </div>
            )}
        </div>
    );
};

export default SellerList;
