import React, { useState } from 'react';
import BuyerForm from './BuyerForm/BuyerForm';
import BuyerList from './BuyerList/BuyerList';
import Modal from '../component/Modal/Modal';

const ParentComponent = () => {
    const [buyers, setBuyers] = useState([]);
        const [showForm, setShowForm] = useState(false);

    const handleAddBuyer = (newBuyer) => {
        setBuyers((prevBuyers) => [...prevBuyers, newBuyer]);
        setShowForm(false);
    };

    const toggleFormVisibility = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    return (
        <div>
            <h1>Buyer Management</h1>
            <button onClick={toggleFormVisibility}>
                {showForm ? 'Hide Form' : 'New Buyer'}
            </button>
            
            <Modal isOpen={showForm} onClose={toggleFormVisibility}>
                <BuyerForm onAddBuyer={handleAddBuyer} />
            </Modal>
            <BuyerList buyers={buyers}/>
            {/* Render buyers list or other components here */}
        </div>
    );
};

export default ParentComponent;
