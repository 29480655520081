// utils/whatsappUtils.js

import { sellerTemplate, buyerTemplate, otherTemplate } from "../whatsapp_temp";

/**
 * Get the WhatsApp message based on selected template
 * @param {string} selectedTemplate - The selected template type (e.g., 'Seller', 'Buyer', 'Other')
 * @param {string} customMessage - The custom message entered by the user (if any)
 * @returns {string} - The WhatsApp message to be sent
 */
export const getWhatsAppMessage = (selectedTemplate, customMessage) => {
    switch (selectedTemplate) {
        case "Seller":
            return sellerTemplate;
        case "Buyer":
            return buyerTemplate;
        case "Other":
            return customMessage || otherTemplate;
        default:
            return otherTemplate; // Default to "Other" if no template is selected
    }
};
