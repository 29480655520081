// /Users/niharika/Documents/GitHub/Enquiry-management-Frontend/src/component/whatsapp.js

/**
 * Open WhatsApp with a pre-filled message
 * @param {string} phoneNumber - The phone number to send the message to.
 * @param {string} message - The message content to send.
 */
export const openWhatsApp = (phoneNumber, message) => {
    // Construct WhatsApp URL
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp
    const whatsappWindow = window.open(whatsappURL, "_blank");
    if (!whatsappWindow) {
        alert("Please allow popups for this site to open WhatsApp.");
    }
};
