import React from 'react';

const EnquiriesBySeller = ({ enquiries }) => {
    return (
        <div class="enquiries-list">
            <h3>Enquiries</h3>
            {enquiries && enquiries.length > 0 ? (
                <ul>
                    {enquiries.map((enquiry) => (
                        <li key={enquiry.enquiryID}>
                            <h4>{enquiry.enquiryID}</h4>
                            <p><strong>Buyer Name:</strong> {enquiry.buyerName}</p>
                            <p><strong>Product:</strong> {enquiry.productType}</p>
                            <p><strong>Status:</strong> {enquiry.status}</p>
                            <p><strong>Description:</strong> {enquiry.description}</p>
                            <p><strong>Location:</strong> {enquiry.city}, {enquiry.state}</p>
                            <p><strong>Date:</strong> {new Date(enquiry.date).toLocaleDateString()}</p>
                            <p><strong>Contact:</strong> {enquiry.emailID} | {enquiry.phoneNumber}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No enquiries found for this seller.</p>
            )}
        </div>
    );
};

export default EnquiriesBySeller;
