import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/global.css'; // Import the CSS file
import EnquiryForm from '../Enquiry/EnquiryForm/EnquiryForm'; // Import the EnquiryForm component

const Home = () => {
    const [enquiryFormVisible, setEnquiryFormVisible] = useState(false); // State to manage enquiry form visibility

    const handleAddEnquiryClick = (e) => {
        e.preventDefault();
        setEnquiryFormVisible(true); // Show the enquiry form
    };

    const handleCloseEnquiryForm = () => {
        setEnquiryFormVisible(false); // Hide the enquiry form
    };

    return (
        <div>
            <h1>Welcome to the Home Page</h1>
            <div class="button-container">
                <Link to="/add-seller"><button>Add Seller</button></Link>
                <Link to="/sellers"><button>View Sellers</button></Link>
                <Link to="/add-buyer"><button>Add Buyer</button></Link>
                <Link to="/buyers"><button>View Buyers</button></Link>
                <Link to="#" onClick={handleAddEnquiryClick}><button>Add Enquiry</button></Link>
                <Link to="/enquiries"><button>View Enquiries</button></Link>
            </div>
            {enquiryFormVisible && (
                <div class="modal">
                    <div class="modal-content">
                        <span class="close" onClick={handleCloseEnquiryForm}>&times;</span>
                        <EnquiryForm onAddEnquiry={() => setEnquiryFormVisible(false)} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;