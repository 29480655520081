// src/SellersPage.js
import React, {useState} from 'react';
import SellerForm from './SellerForm/SellerForm';
import SellerList from './SellerList/SellerList';
import EnquiriesBySeller from './SellerList/enquiriesBySeller'; 
import Modal from '../component/Modal/Modal';

const ParentComponent = () => {
    const [showForm, setShowForm] = useState(false);
    const [sellers, setSellers] = useState([]);
    const [inquiries, setInquiries] = useState([]);

    const handleAddSeller = (newSeller) => {
        setSellers((prevSellers) => [...prevSellers, newSeller]);
        setShowForm(false);
    };

    const toggleFormVisibility = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    const handleFetchInquiries = (fetchedInquiries) => {
        setInquiries(fetchedInquiries); // Update inquiries state
    };

    return (
        <div>
            <h1>Seller Management</h1>
            <button onClick={toggleFormVisibility}>
                {showForm ? 'Hide Form' : 'Add Seller'}
            </button>
            <Modal isOpen={showForm} onClose={toggleFormVisibility}>
                <SellerForm onAddSeller={handleAddSeller} />
            </Modal>
            {/* <SellerForm onAddSeller={handleAddSeller} /> */}
            <SellerList  sellers={sellers}/>
            {/* Render sellers list or other components here */}
        </div>
    );
};

export default ParentComponent;